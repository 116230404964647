import create from "zustand";

export const newAvailableTextStore = create((set) => ({
  newAvailableText: "",
  setNewAvailableText: (val) => set((state) => ({ newAvailableText: val })),
}));

export const availableTextStore = create((set) => ({
  availableText: "",
  setAvailableText: (val) => set((state) => ({ availableText: val })),
}));

export const letterStore = create((set) => ({
  letter: "",
  setLetter: (val) => set((state) => ({ letter: val })),
}));

export const countStore = create((set) => ({
  count: 1000,
  setCount: (val) => set((state) => ({ count: val })),
}));

export const dropButtonStore = create((set) => ({
  dropButton: false,
  setDropButton: (val) => set((state) => ({ dropButton: val })),
}));
