import {
  newAvailableTextStore,
  letterStore,
  availableTextStore,
  dropButtonStore,
} from "./store";

const options = [
  "firstName",
  "lastName",
  "contact",
  "region",
  "birthday",
  "email",
  "address",
  "gender",
  "fatherName",
  "motherName",
  "momoNumber",
  "momoNetwork",
  "bankName",
];

const DropdownComponent = () => {
  const letter = letterStore((state) => state.letter);
  const availableText = availableTextStore((state) => state.availableText);
  const setNewAvailableText = newAvailableTextStore(
    (state) => state.setNewAvailableText
  );
  const dropButton = dropButtonStore((state) => state.dropButton);
  const setDropButton = dropButtonStore((state) => state.setDropButton);

  const insertSelected = (e) => {
    let textToInsert = e.target.innerHTML;
    setNewAvailableText(`${availableText}${textToInsert}`);
  };

  const newOptions =
    options.filter((option) => option.includes(letter)).length < 1
      ? options
      : options.filter((option) => option.includes(letter));

  const handleClick = (e) => {
    insertSelected(e);
    setDropButton(false);
  };

  return (
    <div className="dropdown">
      {/* <button
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        style={{ visibility: dropButton ? "visible" : "hidden" }}
      >
        Dropdown button
      </button> */}
      <div
        className={`${dropButton ? "show" : null} dropdown-menu`}
        aria-labelledby="dropdownMenuButton"
      >
        {newOptions.map((newOption, i) => (
          <a key={i} className="dropdown-item" onClick={(e) => handleClick(e)}>
            {newOption}
          </a>
        ))}
      </div>
    </div>
  );
};

export default DropdownComponent;
