import {
  availableTextStore,
  letterStore,
  countStore,
  dropButtonStore,
} from "./store";

const useHandler = () => {
  const setLetter = letterStore((state) => state.setLetter);
  const setAvailableText = availableTextStore(
    (state) => state.setAvailableText
  );
  const setCount = countStore((state) => state.setCount);
  const setDropButton = dropButtonStore((state) => state.setDropButton);
  const dropButton = dropButtonStore((state) => state.dropButton);

  const handler = (e) => {
    if (e.key === "@") {
      setDropButton(true);
      setAvailableText(e.target.value);
    }

    if (
      e.target.innerHTML.substring(e.target.innerHTML.length - 2).includes("@")
    ) {
      setLetter(e.target.innerHTML.substring(e.target.innerHTML.length - 1));
    }

    //set dropbutton to false if user presses backspace immediately after typing @
    if (
      e.key === "Backspace" &&
      e.target.innerHTML.substring(e.target.innerHTML.length - 1) === "@"
    ) {
      setDropButton(false);
    }

    setCount(1000 - e.target.value.length);
  };

  return handler;
};

export default useHandler;
