import { useState, useEffect, useContext, useRef } from "react";
import { NotificationContext } from "../../contexts/Notification";
import {
  getAllegianceColor,
  getAllegianceColorDNN,
  shortenText,
} from "../../utils";
import EmptyContent from "../EmptyContent";
import { useSession } from "next-auth/client";
import Pagination from "../ui/Pagination";
import { Formik, Field, Form } from "formik";
import { newAvailableTextStore, countStore } from "../../utils/store";
import DropdownComponent from "../../utils/dropdown";
import useHandler from "../../utils/handler";
import { get } from "lodash";

const CustomInputComponent = (props) => (
  <textarea
    className="form-control"
    name="message"
    id="message"
    rows="5"
    placeholder="Happy birthday. Wishing you a very special birthday and a wonderful year ahead!"
    {...props}
  ></textarea>
);

export const randomContacts = (searchValue, filters) => {
  const [_, setShowNotification] = useContext(NotificationContext);
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalContacts, setTotalContacts] = useState(null);
  const [session] = useSession();
  const [number, setNumber] = useState(0);
  const [contactID, setContactID] = useState("");
  const close = useRef();
  const [thereply, setReply] = useState(false);
  const newAvailableText = newAvailableTextStore(
    (state) => state.newAvailableText
  );
  const count = countStore((state) => state.count);

  const fetchRandomContacts = async (page = 1) => {
    setLoading(true);
    try {
      const filter = [];
      filters.region && filter.push(`Region=${filters.region}`);
      filters.pollingStation &&
        filter.push(`PollingStation=${filters.pollingStation}`);
      filters.constituency &&
        filter.push(`Constituency=${filters.constituency}`);
      filters.group && filter.push(`Tag=${filters.group}`);
      const filterQueryParam = filter.join("&");
      const url =
        searchValue || filterQueryParam
          ? `https://nec.dmb2024.com/Contacts/search?Page=${page}${
              searchValue && `&Term=${searchValue}`
            }${filterQueryParam && "&" + filterQueryParam}`
          : "https://nec.dmb2024.com/Contacts/random";
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      });
      const results = await response.json();
      const status = response.status;
      if (status !== 200) {
        setShowNotification({
          show: true,
          message: "An error occurred while fetching contacts",
          type: "danger",
        });
        return;
      }
      setCurrentPage(results.data.pageIndex);
      setTotalContacts(results.data.totalCount);
      setContacts(results.data.results);
    } catch (err) {
      setShowNotification({
        show: true,
        message: "An error occurred while fetching contacts",
        type: "danger",
      });
    } finally {
      setLoading(false);
    }
  };

  const handler = useHandler();

  const handleContact = (contact) => {
    setNumber(contact.contact);
    setContactID(contact.id);
  };

  useEffect(() => {
    fetchRandomContacts();
  }, [searchValue, filters]);

  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
  });

  return (
    <>
      {loading && <p>Loading ...</p>}
      {!loading && contacts.length < 1 && <EmptyContent />}
      {!loading && contacts.length > 0 && (
        <div className="row">
          <div className="col-12">
            <div className="table-responsive-md">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col" className="text-nowrap p-4">
                      Full Name
                    </th>
                    <th scope="col" className="text-nowrap p-4">
                      Phone Number
                    </th>
                    <th scope="col" className="text-nowrap p-4">
                      Region
                    </th>
                    <th scope="col" className="text-nowrap p-4">
                      Constituency
                    </th>
                    <th scope="col" className="text-nowrap p-4">
                      Group
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {contacts.map((contact) => (
                    <tr
                      className="bg-white border-top border-bottom"
                      key={contact.id}
                    >
                      <td className="p-4">
                        <span
                          className="rounded-circle mx-2"
                          style={{
                            width: "2vh",
                            height: "2vh",
                            backgroundColor: `${getAllegianceColor(
                              get(contact, "allegiance") || ""
                            )}`,
                            display: "inline-block",
                          }}
                        ></span>

                        <span
                          className="rounded-circle mx-2"
                          style={{
                            width: "2vh",
                            height: "2vh",
                            backgroundColor: `${
                              contact &&
                              getAllegianceColorDNN(
                                get(
                                  contact?.politicalOrientation,
                                  "allegiance"
                                ) || 0
                              )
                            }`,
                            display: "inline-block",
                          }}
                        ></span>
                      </td>
                      <td className="text-nowrap p-4">
                        <a
                          href={`/contacts/${contact.id}`}
                          className="text-decoration-none text-dark"
                        >
                          <div className="flex-column">
                            {contact.fullname}
                            <small className="d-block text-muted">{contact.position}</small>
                          </div>
                        </a>
                      </td>
                      <td className="text-nowrap p-4">
                        <a
                          href={`/contacts/${contact.id}`}
                          className="text-decoration-none text-dark"
                        >
                          {contact.contact || " - "}
                        </a>
                      </td>
                      <td className="text-nowrap p-4">
                        <a
                          href={`/contacts/${contact.id}`}
                          className="text-decoration-none text-dark"
                        >
                          {contact.region || " - "}
                        </a>
                      </td>
                      <td className="text-nowrap p-4">
                        <a
                          href={`/contacts/${contact.id}`}
                          className="text-decoration-none text-dark"
                        >
                          {contact.constituency || " - "}
                        </a>
                      </td>
                      <td className="text-nowrap p-4">
                        <a
                          href={`/contacts/${contact.id}`}
                          className="text-decoration-none text-dark"
                        >
                          {shortenText(contact.tags.join(", ") || " - ")}
                        </a>
                      </td>
                      <td className="order-md-last order-first text-nowrap p-4 text-right">
                        <a href={`/contacts/${contact.id}/edit`}>
                          <img
                            data-toggle="tooltip"
                            data-placement="top"
                            data-original-title="Edit Contact"
                            aria-describedby="tooltip313713"
                            src="https://designs.dmb2024.com/assets/images/icons/edit.svg"
                            width="17"
                            alt="edit"
                            className="mr-2"
                          />
                        </a>
                        {contact && (
                          <span>
                            <a
                              href="#"
                              onClick={() => handleContact(contact)}
                              data-toggle="modal"
                              data-target="#sendSMSToAllModal"
                              className="mx-2"
                            >
                              <img
                                data-toggle="tooltip"
                                data-placement="top"
                                data-original-title="Send Message"
                                aria-describedby="tooltip313713"
                                src="https://designs.dmb2024.com/assets/images/icons/message-circle.svg"
                                width="17"
                                alt="message"
                              />
                            </a>
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <Formik
            enableReinitialize
            initialValues={{ message: newAvailableText }}
            onSubmit={async (values, { resetForm }) => {
              await new Promise((resolve) => setTimeout(resolve, 500));

              const response = await fetch(
                `https://notifications.dmb2024.com/notifications/sms/campaigns`,
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${session.token}`,
                  },
                  body: JSON.stringify({
                    content: values.message,
                    totalRecipients: 0,
                    allowReply: true,
                  }),
                }
              );
              const result = await response.json();

              let myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");
              myHeaders.append("Authorization", `Bearer ${session.token}`);

              let raw = JSON.stringify({
                content:
                  thereply === true
                    ? `${values.message}.\n\n To reply, go to https://sms.drbawumia.com/reply/${result?.data?.replyId}`
                    : values.message,
                campaignId: result?.data?.id,
              });

              let requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
              };

              fetch(
                `https://nec.dmb2024.com/contacts/${contactID}/send-sms`,
                requestOptions
              )
                .then((response) => response.text())
                .then(close.current.click())
                .then(resetForm())
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            <div
              className="modal fade"
              id="sendSMSToAllModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog custom-modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0">
                    <button
                      type="button"
                      ref={close}
                      className="close font-weight-light"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <img
                        src="https://designs.dmb2024.com/assets/images/icons/x-square.svg"
                        alt="close"
                      />
                    </button>
                  </div>
                  <Form>
                    <div className="modal-body px-3 px-md-5">
                      <h6
                        className="text-center mb-4 text-dark"
                        id="exampleModalLabel"
                      >
                        Send a birthday message
                      </h6>
                      <div className="input-group mb-4">
                        <div className="input-group-prepend">
                          <img
                            src="https://designs.dmb2024.com/assets/images/icons/phone.svg"
                            width="40"
                            className="input-group-text bg-white img-fluid"
                            alt="phone"
                          />
                        </div>
                        <Field
                          type="tel"
                          className="form-control"
                          name="contact"
                          value={number}
                          placeholder="Phone"
                          aria-label="Phone"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                      <div className="form-group">
                        <Field
                          as={CustomInputComponent}
                          name="message"
                          placeholder="Please type in message here"
                          maxLength="1000"
                          onKeyUp={(e) => {
                            handler(e);
                          }}
                        />
                        <DropdownComponent />

                        <small className="grey-text">
                          {count} characters remaining
                        </small>
                      </div>
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          name="enableReply4"
                          id="enableReply4"
                          onChange={() => setReply(!thereply)}
                        />
                        <label
                          className="custom-control-label order-last"
                          for="enableReply4"
                        >
                          Enable Reply
                        </label>
                      </div>
                    </div>
                    <div className="modal-footer border-0 mt-n4 mb-5 px-3 px-md-5">
                      <button
                        type="submit"
                        className="btn btn-primary btn-md btn-block"
                      >
                        Send message
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Formik>
        </div>
      )}
      {!loading && (
        <Pagination
          onPageChanged={(data) => {
            fetchRandomContacts(data.currentPage);
          }}
          totalRecords={totalContacts}
          currentPage={currentPage}
          pageLimit={20}
        />
      )}
    </>
  );
};
